/****************************************

DASHBOARD

****************************************/

.dashboard {
  .card {
    margin-bottom: $padding--sm;

    &.drop-box {
      @media (max-width: $mobile) {
        padding: $paddingMain $padding--xs;
      }
    }

    .btn {
      @media (max-width: theme('screens.lg')) {
        width: 100%;
      }
    }

    &.learning-module, &.designations-module {
      .title3 {
        margin-bottom: $paddingMain;
        text-decoration: underline 1px solid color(accent);
        text-underline-offset: 1rem;
        line-height: 1.5;
      }
    }

    .card-header {
      @media (max-width: theme('screens.lg')) {
        flex-direction: column;
        align-items: flex-start;
      }

      a {
        margin-bottom: 2rem;
      }
    }

    .top-divider {
      @media (max-width: theme('screens.lg')) {
        display: none;
      }
    }

    .designation {
      font-size: 2.4rem;
    }

    .content-wrapper {
      margin-top: 2rem;

      @media (min-width: calc(theme('screens.lg') + 1px)) {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 26fr 45fr 24fr;
        grid-column-gap: $padding--lg;
      }

      &.khplus {
        grid-template-columns: 26fr 50fr 19fr;
      }

      .progress-bar-wrapper {
        min-height: 200px;
        padding: 0 2rem;
        .shield-logo-box {
          margin: 0 auto;
          display: block;
          img {
            height: 100px;
          }
        }
      }

      .progress-bar-wrapper,
      .no-enrollment-img,
      .completion-img,
      .on-demand-img {
        @media (max-width: theme('screens.lg')) {
          margin-bottom: 2rem;
        }
      }

      .small-headline {
        margin-bottom: 2rem;
      }

      ul {
        margin-bottom: 2rem;

        li {
          margin-bottom: 1.5rem;
        }
      }

      .see-all {
        font-weight: 600;

        svg {
          margin-left: 0.5rem;
          vertical-align: middle;
          display: inline;
        }
      }
    }

    .bottom-divider {
      margin: 2rem 0;
    }

    &.learning-module {
      .next-actions {
        @media (min-width: theme('screens.lg')) {
          display: flex;
        }

        .vertical-hr {
          margin-right: 2rem;

          @media (max-width: theme('screens.lg')) {
            display: none;
          }
        }

        .horizontal-hr {
          margin: 2rem 0;

          @media (min-width: theme('screens.lg')) {
            display: none;
          }
        }

        p {
          margin-bottom: 0;
        }

        .underline-link {
          margin-bottom: 2rem;
        }

        .webinar-link {
          margin-bottom: $gap;
        }

        .action-buttons {
          width: 100%;

          .btn {
            font-size: 1.2rem;
            width: 100%;
            margin: 1rem 0;
          }
        }
      }
    }

    &.designations-module {

      &.no-designations .content-wrapper {
        display: block;
      }

      .recert-notif {
        display: flex;
        gap: 0.5rem;
        align-items: flex-start;
        font-weight: 600;
        background-color: color(accent, lightRed);
        padding: 0.5rem 1rem;
        border-radius: 5px;

        svg {
          margin-top: 5px;

          @media (max-width: $mobile) {
            margin-top: 7px;
          }
        }
        &.recert-info{
          background-color: color(accent, lightMist); 
        }
      }

      .title4 {
        margin-bottom: 2rem;
      }

      .badges.mobile {
        .accordion-header {
          border-bottom: none;
          justify-content: space-between;

          .title4 {
            margin-bottom: 0;
          }

          .accordion-icon {
            font-size: 3rem;
          }
        }
      }

      .designation-icons {
        display: grid;
        grid-column-gap: $padding--sm;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: $padding--sm;

        @media (max-width: theme('screens.lg')) {
          grid-template-columns: repeat(1, 1fr);
        }

        .designation-icon {
          display: flex;
          align-items: center;
          gap: 2rem;

          .badge-image {
            width: 33%;
          }
          img{
              cursor: pointer;
          }  
        }
      }
    }

    &.saved-resource-module {
      margin: $padding--lg 0;
    }
  }

  .promotional-callout {
    padding: $padding--lg $padding--sm;
    background: $grad2Reverse;
    color: white;
    height: 100%;

    @media (max-width: $mobile) {
      text-align: center;
    }

    p {
      color: white;
      margin-bottom: $padding--lg;

      @media (max-width: $mobile) {
        margin-bottom: $paddingMain;
      }
    }

    .btn {
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  .quick-links {
    padding: $padding--lg $padding--sm;
    background: $grad5Reverse;
    height: 100%;

    @media (max-width: $mobile) {
      text-align: center;
    }

    ul {
      li {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.complete-modal {
  .title2 {
    margin-bottom: $padding--lg;
  }

  p {
    margin-bottom: $padding--sm;
  }

  .subtitle {
    font-weight: 600;
  }
}

.knowledge-hub-module {
  .title3 {
    margin-bottom: 30px;
    a, button {
      border-bottom: 1px solid color(accent, reflexBlue);
    }
  }
  .column-layout .btn {
    margin-bottom: 20px;
  }
  .khplus {
    .khplus-box {
      background: $grad5;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      @media (max-width: theme('screens.lg')) {
        max-width: 342px;
      }
      min-height: 200px;
  
      .plus {
        font-size: 4rem;
        line-height: 0;
        color: color(accent, reflexBlue);
        font-weight: bold;
      }
    }
  }
}
