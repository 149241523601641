/****************************************

PACKAGE POPUP

****************************************/

.package-modal {
  .container--sm {
    @media (max-width: theme('screens.sm')) {
      padding: 0 1.5rem;
    }
  }

  .title2 {
    margin-bottom: 2rem;
  }

  .title2,
  .package-message {
    text-align: center;
  }

  .btn {
    @media (min-width: theme('screens.lg')) {
      margin: auto;
    }
  }
}
