.tabs-layout {
  margin-bottom: $padding--sm;
  border-bottom: 1px solid color(black);

  * {
    overflow: visible !important;
  }

  .MuiTabs-flexContainer {
    justify-content: space-around;
  }

  button {
    font-size: 1.6rem;
    font-family: inherit;
    font-weight: 600;
    text-transform: none;
    color: inherit !important;
    flex-grow: 1;
  }

  .tabs-indicator {
    background-color: color(accent, reflexBlue);
    height: 3px;
  }

  @media (max-width: $mobile) {
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
  }
}

.tabs-layout-mobile {
  margin-bottom: $paddingMain;

  @media (min-width: calc($mobile + 1px)) {
    display: none;
  }
}

.MuiTab-root.Mui-disabled {
  pointer-events: auto !important;
  color: color(accent, smoke);
  cursor: not-allowed !important;
  opacity: .5;
}
