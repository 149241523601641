.company-report-banner {
  background: $grad5Reverse;
  padding: 1.5rem 0;

  @media (max-width: $mobile) {
    .container {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  p {
    margin-bottom: 0;
    color: color(accent, midnight);
  }

  a {
    color: color(accent, midnight);
    border-bottom: 1px solid color(accent, midnight);
    line-height: 1.2;
  }
}

.select-report {
  position: relative;
  .tooltip-wrapper {
    position: absolute;
    top: 3px;
    left: 120px;
  }
}

.report-display {
  form {
    margin-top: 40px;
  }
  table {
    max-width: 100%;
    overflow: scroll;
    display: block;
    margin-bottom: 20px;
    th {
      background-color: color(accent, midnight);
      color: color(white, base);
      border: 1px solid color(white, transparent);
      font-weight: normal;
      &:after {
        content: "";
        display: inline-block;
        height:20px;
        width: 20px;
        background: url(../../../assets/anchor-up-arrow-caret.svg);
        background-size: contain;
        margin: 0 0 -4px 10px;
        transform: rotate(180deg);
        opacity: 0.3;
      }
      &.asc,
      &.desc {
        font-weight: bold;
        &:after {
          opacity: 1;
        }
      }
      &.desc:after {
        transform: rotate(0deg);
      }
    }
    td {
      min-width: 210px;
      text-align: center;
      padding: 20px;
      /*background-color: color(accent, midnight);*/
      /*color: color(white, base);*/
      border: 1px solid black;
      p {
        /*color: color(white, base);*/
        margin-top: 0;
      }
    }
    button {
      color: color(accent, base);
      text-decoration: underline;
    }
  }
  #companyReportModalContent {
    border: 1px solid color(accent, midnight);
    padding: 40px;
    background-color: color('background', base);
    .section-header {
      margin-top: 40px;
    }
    .report-header {
      @extend h5;
    }
  }
  #designationReport {
    margin-bottom: 40px;
  }
  .download-wrapper {
    display: flex;
    justify-content: right;
    margin-top: 60px;
  }
  .download {
    text-decoration: underline;
  }
  .modal-body {
    max-height: 60vh;
    max-width: 80%;
    overflow: scroll;
    scrollbar-color: color(accent, lightGray);

    /* width */
    &::-webkit-scrollbar {
      height: 1.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 100px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: color(accent, lightGray);
      border-radius: 100px;
    }
  }
  @media (max-width: $mobile) {
    .clear {
      background-color: transparent;
      color: color(accent, midnight);
      padding: 30px 0;
    }
  }
}
