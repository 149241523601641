.maintenance {
  padding: 20px 20px;
  background: $grad2;
  height: 100vh;

  .maintenance-main {
    margin-top: 5rem;
    .maintenance-title, .maintenance-text {
      color: #ffffff;
    }
  }
}