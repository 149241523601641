/****************************************

DESIGNATIONS & CERTIFICATIONS SECTION

****************************************/
.designations-certifications {
  width: 100%;

  .recertify {
    background: color(accent, red);
    max-width: 1000px;
    color: color(white);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: $padding--sm;

    @media (max-width: $mobile) {
      padding: $padding--xs;
      flex-direction: column;
      align-items: flex-start;
      margin: $padding--xs calc(-1 * $padding--xs) 0;
    }

    .recert-headline {
      display: flex;
      align-items: center;
      gap: $paddingMain;

      @media (max-width: $mobile) {
        gap: $padding--xs;
      }

      .title2 {
        margin: 0;
      }
    }

    img {
      width: 47px;
      height: auto;
    }

    a {
      color: color(white);
      border-bottom-color: color(white, transparent);
      margin-top: 20px;
    }
  }

  .reinstate {
    .global-status {
      padding-top: $paddingMain;
      margin-bottom: 0;
    }

    .btn {
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  .designations-list {
    background: color(white);
    @include paddingTopBottom;
    margin-bottom: $padding--lg;

    @media (max-width: $mobile) {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      margin: 0 $padding--xs;
    }

    .designation {
      display: grid;
      grid-template-columns: 110px 50fr 15fr;
      grid-column-gap: $padding--lg;
      @include paddingTopBottomSm;

      &:not(:last-of-type) {
        border-bottom: 1px solid color(accent, lightGray);
      }

      .table .cell:first-of-type {
        width: auto;
      }

      @media (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .table .cell:first-of-type {
          padding-left: 0;
        }

        button, a.btn {
          width: 100%;
        }
      }

      .col:nth-of-type(3) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
      }

      img {
        @media (max-width: $mobile) {
          max-width: 40%;
        }
      }
    }
  }

  .ce-callout {
    background: $grad2;
    color: color(white);
    margin-bottom: $padding--lg;
    padding: calc($padding--lg + $paddingMain);

    @media (max-width: $mobile) {
      text-align: center;
      padding: $paddingMain $padding--xs;
      margin-bottom: $paddingMain;
    }

    p {
      color: color(white);
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 2rem;

      @media (max-width: $mobile) {
        flex-direction: column;

        .btn {
          width: 100%;
        }
      }
    }
    &.couplr{
        max-width:1000px;
        @media (max-width: $mobile) {
            text-align: left;
        }
    }
  }
}

.recertification {

  &.container--full {
    margin-bottom: 0;
  }

  .recert-steps {
    background: color(white);
    padding: $padding--lg 0 $paddingMain;

    @media (max-width: $mobile) {
      padding-top: $paddingMain;
    }

    .recert-step {
      &:not(:first-of-type) {
        padding-top: $padding--lg;

        @media (max-width: $mobile) {
          padding-top: $paddingMain;
        }
      }

      &:not(:last-of-type) {
        padding-bottom: $padding--lg;
        border-bottom: 1px solid color(accent, lightGray);

        @media (max-width: $mobile) {
          padding-bottom: $paddingMain;
        }
      }

      p {
        @media (min-width: calc($mobile + 1px)) {
          margin-bottom: 0;
        }
      }

      .btn {
        margin: 0 auto 1rem;

        @media (max-width: $mobile) {
          width: 100%;
          margin-top:1em;
        }
      }

      .due-date {
        font-weight: 800;
        &.deadline{
            color: #dc3c46;
        }
        &.completed{
           color: #14AC3F; 
        }
        @media (min-width: calc($mobile + 1px)) {
          text-align: center;
        }
      }
    }
  }
}

.ethics-questionnaire {
  .info-header,
  .question-header {
    border-bottom: 1px solid color(accent, lightGray);
    padding-bottom: $paddingMain;
  }

  .q-intro {
    margin-top: $paddingMain;
  }

  .card-layout {
    margin-top: $paddingMain;

    @media (min-width: $mobile) {
      margin-bottom: $paddingMain;
    }
  }

  .radios {
    @media (min-width: $mobile) {
      display: flex;
      gap: 2rem;
    }

    &.learn-more {
      padding-bottom: 2rem;
    }
  }

  .questionnaire-form .field label,
  .questionnaire-form .field .radio-question,
  .questionnaire-form .inline-field label {
    font-weight: 400;
  }

  .tooltip-wrapper {
    margin-bottom: 1rem;
  }

  .questionnaire-form .field,
  .yes-instructions,
  .promo-section {
    border-bottom: 1px solid color(accent, lightGray);
    padding: $padding--sm 0;
    margin: 0;
  }

  .yes-instructions ul {
    margin-bottom: 0;
  }

  .radio-question {
    padding-bottom: 2rem;
  }

  .yes-description {
    padding-top: $padding--sm;
  }

  .promo-submit {
    display: flex;
    gap: 2rem;

    @media (max-width: theme('screens.lg')) {
      flex-direction: column;
    }

    label {
      margin-bottom: 0;
    }
  }

  .attestation {
    padding-top: $padding--sm;

    ol li {
      padding-bottom: 1rem;
    }

    .checkboxes > li {
      padding-bottom: 2rem;
    }
  }

  .cf-modal {
    text-align: center;

    .buttons {
      display: flex;
      justify-content: center;
      gap: 2rem;

      @media (max-width: $mobile) {
        flex-direction: column;
      }
    }
  }

  .promo-error {
    margin-top: 1rem;
  }

  .submit-error {
    text-align: center;
    margin-top: 1rem;
  }
}

.ce-reporting {
  &.container--full {
    margin-bottom: 0;
  }
  .cta-sections{
      .card {
        flex: 1;
        @media (max-width: $mobile) {
            flex: none;
        }
      }
      .card-holder{
          padding-bottom:1.5em;
          > div {
              min-width:150px;
          }
          .h4{
              max-width:95%;
          }
          h4{
              @media (max-width: $mobile) {
                padding-bottom: .5em;
              }
          }
          .btn.modal-trigger{
              @media (max-width: $mobile) {
                width:100%;
              }
          }
      }
      .card-content{
          padding-top:1.5em;
      }
      h3{ padding-top:1em;padding-bottom:1em }
      h3.selectionHeadline{ text-align: center; padding-bottom:2em  }
  }
  .intro-content-wrapper {
    @media (min-width: $mobile) {
      //padding-bottom: $padding--lg;
    }
  }
  
  .cta-section {
    border-top: 1px solid color(accent, lightGray);
    padding-top: $padding--lg;
    padding-bottom: $padding--lg;

    @media (max-width: $mobile) {
      flex-direction: column;
      padding-top: $paddingMain;
      padding-bottom: $paddingMain;

      &:last-child {
        border-bottom: 1px solid color(accent, lightGray);
        margin-bottom: $paddingMain;
      }

      .modal-wrapper,
      .modal-trigger {
        width: 100%;
      }
    }

    .description {
      @media (min-width: $mobile) {
        width: 50%;

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .ce-chart {
    padding-top:6.5rem;
    padding-bottom: $padding--sm;

    .recert-notif {
      display: flex;
      gap: 1rem;
      font-weight: 600;
      background-color: color(accent, lightRed);
      padding: 1rem 1rem;
      border-radius: 5px;
      /*margin-top: 2rem;*/
      margin-bottom: 0;
      width:100%;
      @media (min-width: theme('screens.lg')) {
        width: 50%;
      }

      svg {
        width: 25px;
        height: 25px;
        margin-top: 0.5rem;
      }
       &.recert-info{
          background-color: color(accent, lightMist); 
        }
    }

    table {
      th,
      td {
        white-space: nowrap;
      }

      td {
        vertical-align: top;
      }

      tr.has-edit td {
        padding-bottom: 2rem;
      }

      tr.no-edit td {
        padding-bottom: 4rem;
      }

      .edit-chart {
        padding-top: 1rem;
        display: flex;
        gap: 1rem;
      }
    }

    .chart-subtext {
      @media (min-width: theme('screens.lg')) {
        width: 50%;
      }
    }
    .ceStatus{
/*        display:flex;
        justify-content:space-between;
        flex-wrap: no-wrap;
        @media (max-width: $mobile) {
           flex-wrap: wrap;
        }*/
        
/*        .creditStatus{
           flex-shrink: 0;
           width: calc((100% - 3.5rem) / 2);
            @media (max-width: $mobile) {
              width:100%;
            }  
            & div:first-child{
                padding-bottom:.5em;
            }
        }*/
        .ceMsg {
            margin-top:1rem;
/*            width: calc((100% - 3.5rem) / 2);
            .recert-notif{
                width:100%;
            }
            @media (max-width: $mobile) {
              width:100%;
              margin-top:.5em;
            } */
        }
     }
  }

  .modal-wrapper {
    white-space: normal;

    .category {
      margin-bottom: $padding--sm;
    }

    .small-headline {
      margin-bottom: 1.5rem;
    }

    ol {
      list-style-type: lower-alpha;
      margin-left: 2rem;
    }

    .category-field {
      margin-bottom: $padding--sm;
    }

    .modal-buttons {
      display: flex;
      justify-content: center;
      gap: 2rem;
      padding-top: $padding--sm;

      @media (max-width: theme('screens.lg')) {
        flex-direction: column;
      }
    }
  }

  .error {
    text-align: center;
    margin-top: 1rem;
  }
}

.faqs {
  background: color(white);
  padding: $padding--lg 0;

  @media (max-width: theme('screens.lg')) {
    padding: $padding--xs 0;
  }

  h2 {
    padding-bottom: $padding--sm;
    border-bottom: 1px solid color(accent, lilac);
  }

  .accordion-item {
    border-bottom: 1px solid color(accent, lilac);
    padding: 2rem $paddingMain;

    @media (max-width: $mobile) {
      padding: 1rem 0;
    }

    &.open {
      .accordion-details {
        padding: 0;
      }
    }

    .accordion-header {
      text-transform: none;
      color: inherit;
      justify-content: space-between;
      border: none;

      .accordion-icon {
        margin-bottom: 2rem;
        font-size: 3rem;
      }
    }

    .accordion-details {
      max-width: 760px;
    }
  }
}

.recert-status-wrapper, .recert-status {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.goodStanding{
    color: #14AC3F;
}
.badStanding{
    color: #DC3C46;
}
.certLink{
    margin-right:1em;
}
.actionItems{
    @media (max-width: $mobile) {
      margin-top:1em;
    }
    display:flex;
    .list{
        width:100%;
        padding-left:1em;
    }
}