.status-bar-container {
  text-align: center;
  max-width: 60%;
  min-width: fit-content;
  margin: auto;
  margin-bottom: 4em;

  @media (max-width: $mobile) {
    margin: 0;
    padding: 0;
    max-width: min-content;
  }
  position: relative;
}
.status-bar {
  position: relative;
  align-items: flex-start;
  display: flex;
  gap: 6em;

  // modified to try to get mobile looking okay
  @media (max-width: $mobile) {
    justify-content: center;
    padding: 0;
    margin: 0;
    gap: 0;
  }
}

.status-card {
  pointer-events: none;

  &.active {
    pointer-events: auto;
    cursor: pointer;
  }
}

.crumb-card {
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5em;
  height: 5em;

  &.active-page {
    background-color: color(accent, base);
  }
  &.completed-page {
    background: $grad5;
  }
  &.inactive-page {
    border: 0.01em solid color(accent, midnight);
  }
  // modified to fit content @ mobile
  @media (max-width: $mobile) {
    width: 2.5em;
    height: 2.5em;
    h4 {
      font-size: 1.5em;
    }
  }
}

.active-page h4 {
  color: color(white);
  padding: 0;
  margin: 0;
}

.completed-page h4 {
  color: color(accent, midnight);
  padding: 0;
  margin: 0;
}

.inactive-page h4 {
  color: color(accent, midnight);
  padding: 0;
  margin: 0;
}

.status-card p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: calc(25 / 18);
  text-align: center;
  color: color(accent, midnight);
  // this @ media added to attempt to make mobile look okay for now
  @media (max-width: $mobile) {
    font-size: 1em;
    font-weight: 400;
  }
}
.card-layout {
  position: relative;

  &.status-card {
    flex-direction: column;
    align-items: center;
    width: 7em;

    @media (max-width: $mobile) {
      width: 5em;
    }
  }
}

.click-disabled {
  pointer-events: none;
  cursor: default;
}
