/****************************************

TOOLTIP

****************************************/

.tooltip-wrapper {
  line-height: 0;

  .tooltip-toggle {
    cursor: pointer;
  }

  .MuiTooltip-tooltip {
    background-color: color(accent, lightMist);
    border-radius: 0;
    padding: 2rem;

    .title4 {
      color: color(accent, midnight);
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
