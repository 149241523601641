.graduate_application-page {
  ul.uploadDocuments {
    list-style: disc;
    margin: 0 20px 20px;
  }
  .card-layout {
    padding: 40px;
  }
  #bachelors-radios + .radios {
    input {
      display: inline;
    }
  }
  button[type="submit"] {
    margin-top: 20px;
  }
}