.table {
  .row {
    display: table-row;

    &.hidden {
      display: none;
    }

    .cell {
      display: table-cell;
      padding: 1rem;
      vertical-align: top;

      &:first-of-type {
        font-weight: 600;
        color: color(accent, darkGray);
        width: 33%;

        @media (min-width: theme('screens.lg')) {
          text-align: right;
        }
      }

      &:last-of-type {
        @media (max-width: theme('screens.lg')) {
          word-break: break-word;
        }
      }
    }
  }
}

.chart-table {
  padding-top: 2em;
  text-align: left;
  margin-bottom: 2rem;
  display: block;
  overflow-x: scroll;
  scrollbar-color: color(accent);

  @media (max-width: $mobile) {
    margin-right: calc(-1 * $padding--xs);
    padding-right: $padding--xs;
    padding-bottom: $padding--xs;
  }

  /* width */
  &::-webkit-scrollbar {
    height: 1.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 100px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: color(accent);
    border-radius: 100px;
  }

  .table-auto {
    width: 75%;
  }

  thead th {
    padding: 1.5rem 1rem;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.15rem;
  }

  tbody td {
    padding: 1rem;
    background: color(white);
    border: 1px solid color(black);
  }
}
