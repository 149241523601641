/****************************************

MY PROFILE

****************************************/

.my-profile {
  .intro-content-wrapper {
    padding-bottom: $padding--lg;

    @media (max-width: $mobile) {
      padding-bottom: $padding--xs;
    }
  }

  .progress-bar-wrapper {
    margin-left: 50%;

    @media (max-width: theme('screens.lg')) {
      min-height: 300px;
      margin-left: 0;
      margin-top: $padding--sm;
    }
  }

  .profile-tabs, .tabs-layout-mobile {
    .tooltip-wrapper h5 {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .tabs-layout-mobile label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .info-missing {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .info{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    float:right;
    h5{
        display:inline-flex;
        align-items: center;
    }
  }

  .missing-field {
    font-size: 1.2rem;
    color: color(accent, red);
  }

  .save-error {
    color: color(accent, red);
  }

  .card-layout {
    justify-content: flex-start;
  }

  .card {
    min-height: 300px;

    &.editing {
      border: 1px solid color(accent, reflexBlue);
    }

    @media (max-width: theme('screens.md')) {
      padding: $padding--xs;
    }

    @media (max-width: $mobile) {
      form {
        height: 100%;
        display: flex;
        flex-direction: column;
        &.mfaSection{
            height: inherit;
        }
      }
    }

    .card-intro {
      padding: 2rem 1rem 0;
    }
  }

  .card-header {
    padding-bottom: 2rem;
  }

  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='date'],
  input[type='phone'],
  input[type='address'],
  input[type='city'],
  input[type='zip'],
  select,
  textarea {
    width: 100%;
  }

  .display-data,
  .info-form {
    margin-top: 2rem;
    width: 100%;

    @media (max-width: $mobile) {
      margin-bottom: 1rem;
    }
  }
  .table.display-data{
      .row {
          .cell:nth-child(2){
              overflow-wrap: anywhere;
          }
      }
  }
  .primary-footnote {
    font-size: 1.3rem;
    margin: 1rem 0;
  }

  .table.info-form .row {
    .cell {
      padding: 0.25rem 1rem;

      &.primary-checkbox {
        padding-bottom: 1rem;
      }
    }

    &.password-req .cell {
      padding-top: 1rem;

      p, ul {
        margin-bottom: 0.5rem;
      }
    }
  }
  .button-wrapper{
      @media (min-width: calc($mobile + 1px)) {
        display:inline-flex;
      }
      .desktop-save,
      .desktop-cancel {
            margin: 0 .25rem;
        }
      .mobile-save,
      .mobile-cancel {
         margin: .5rem 0; 
      }  
  }
  .desktop-save,
  .desktop-edit,
   .desktop-cancel {
    @media (max-width: theme('screens.lg')) {
      display: none;
    }
  }

  .mobile-save,
  .mobile-edit,
  .mobile-cancel{
    width: 100%;
    margin-top: auto;

    @media (min-width: calc($mobile + 1px)) {
      display: none;
    }
  }
  div.inputField.check{
      margin-left: 2rem;
      label {
          position:relative;
      }
  }
  div.inputField.radio{
      /*margin-left: 2rem;*/
      label {
          position:relative;
          padding: 0 2rem 0 0;
          input[type=radio]:after{
              left: 3rem;
          }
      }
  }
   p.fileName{
    display:inline;
    float: left;
    margin-right: 10px;
  }
  button.removeFile{
    padding: 0 0 0 .5em; 
    color: #ff0000;
    font-weight: bold;
  }
  h5 {
      margin-top: 20px;
      margin-bottom: 10px;
      .verify{
           margin-top: 30px;
      }
  }
  .resendLink{
      margin-top:30px;
  }
}
