// import tailwind classes
@tailwind base;
@tailwind components;
@tailwind utilities;

// import custom fonts
@font-face {
  font-family: 'Graphik';
  src: local('Graphik'),
    url('./fonts/Graphik/Graphik-Regular-Web.woff') format('woff'),
    url('./fonts/Graphik/Graphik-Regular-Web.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: local('Graphik'),
    url('./fonts/Graphik/Graphik-RegularItalic-Web.woff') format('woff'),
    url('./fonts/Graphik/Graphik-RegularItalic-Web.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: local('Graphik'),
    url('./fonts/Graphik/Graphik-Medium-Web.woff') format('woff'),
    url('./fonts/Graphik/Graphik-Medium-Web.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: local('Graphik'),
    url('./fonts/Graphik/Graphik-MediumItalic-Web.woff') format('woff'),
    url('./fonts/Graphik/Graphik-MediumItalic-Web.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: local('Graphik'),
    url('./fonts/Graphik/Graphik-Bold-Web.woff') format('woff'),
    url('./fonts/Graphik/Graphik-Bold-Web.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: local('Graphik'),
    url('./fonts/Graphik/Graphik-BoldItalic-Web.woff') format('woff'),
    url('./fonts/Graphik/Graphik-BoldItalic-Web.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: local('Graphik'),
    url('./fonts/Graphik/Graphik-Regular-Web.woff') format('woff'),
    url('./fonts/Graphik/Graphik-Regular-Web.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cambon-Demi';
  src: local('Cambon-Demi'),
    url('./fonts/Cambon/Cambon-Demi.woff') format('woff'),
    url('./fonts/Cambon/Cambon-Demi.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cambon-Demi';
  src: local('Cambon-Demi'),
    url('./fonts/Cambon/Cambon-DemiItalic.woff') format('woff'),
    url('./fonts/Cambon/Cambon-DemiItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

// import TAC master styles
@import './styles/main.scss';
