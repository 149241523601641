/****************************************

SELECT BOX

****************************************/

select, .search-dropdown-btn {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3e%3cpath fill='%234200ED' d='M5 6L0 0H10L5 6Z'/%3e%3c/svg%3e");
  background-size: 10px 6px;
  background-position: right 1rem center;
  background-repeat: no-repeat;
  appearance: none;
  border: 1px solid color(black);
  border-radius: 2px;
  font-size: 1.3rem;
}
