/****************************************

FOOTER

****************************************/

footer {
  @include paddingTopBottom;

  p {
    margin-bottom: 0;
    line-height: 1.4;
    font-size: inherit;
  }

  .footer-link-container {
    @media (min-width: theme('screens.lg')) {
      display: block;
      columns: 3;
      column-gap: 2rem;
    }
  }

  .footer-link-group {
    padding-bottom: 1rem;
  }

  .footer-link-header {
    padding-bottom: 0.25rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.15rem;
  }

  .footer-link {
    padding-bottom: 0.25rem;
  }

  .footer-info-box {
    @media (min-width: theme('screens.lg')) {
      width: 33%;
    }

    .logo-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 2rem;

      .lifes-work {
        width: 80%;
      }

      .title3 {
        margin-bottom: 0.5rem;
      }

      .underline-link {
        padding-top: 0.5rem;
      }
    }

    .disclaimer {
      line-height: 1.2rem;
      padding-top: 2rem;
    }
  }

  .bottom-row {
    align-items: center;

    @media (min-width: theme('screens.lg')) {
      flex-direction: row;
    }

    .socials {
      margin-bottom: 2.5rem;
      gap: 1.5rem;

      a {
        transition: opacity 0.5s;
        &:hover {
          opacity: 0.4;
        }
      }

      @media (min-width: theme('screens.lg')) {
        order: 2;
        margin-bottom: 0;
      }
    }

    .links-container {
      align-items: center;

      @media (min-width: theme('screens.lg')) {
        flex-direction: row;
        order: 1;
        justify-content: flex-start;
      }

      .links-row {
        @media (max-width: theme('screens.lg')) {
          padding-bottom: 0.25rem;
        }
      }
    }
  }
}

.portal footer {
  padding: calc($gap * 2) 0;
  background: color(white);
  font-size: 1.2rem;

  @media (max-width: $mobile) {
    padding: $paddingMain 1.5rem;
  }

  ul {
    font-size: 1.2rem;
    display: flex;
    align-items: center;

    @media (max-width: $mobile) {
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        &:after {
          content: '';
          width: 1px;
          height: 12px;
          margin: 0 $gap;
          background: color(black);
          display: block;
        }
      }

      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        @media (max-width: $mobile) {
          &:after {
            display: none;
          }
        }
      }

      &:nth-of-type(4),
      &:nth-of-type(5) {
        @media (max-width: $mobile) {
          width: 100%;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

