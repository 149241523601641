/****************************************

TYPOGRPAHY

****************************************/

@mixin h1-font-styles {
  font-family: $headlineFont;
  font-weight: 400;
  font-size: 6rem;
  line-height: calc(63 / 60);

  @include elementSpacing;

  @media (max-width: $mobile) {
    font-size: 3.6rem;
    line-height: calc(39 / 36);
  }
}

h1,
.title1 {
  @include h1-font-styles();
}

@mixin h2-font-styles {
  font-family: $headlineFont;
  font-weight: 400;
  font-size: 4.8rem;
  line-height: calc(48 / 48);

  @include elementSpacing;

  @media (max-width: $mobile) {
    font-size: 2.9rem;
    line-height: calc(34 / 29);
  }
}

h2,
.title2 {
  @include h2-font-styles();
}

@mixin h3-font-styles {
  font-family: $headlineFont;
  font-weight: 400;
  font-size: 3.7rem;
  line-height: calc(37 / 37);

  @include elementSpacing;

  @media (max-width: $mobile) {
    font-size: 2.7rem;
    line-height: calc(27 / 24);
  }
}

h3,
.title3 {
  @include h3-font-styles();
}

@mixin h4-font-styles {
  font-family: $headlineFont;
  font-weight: 400;
  font-size: 2.7rem;
  line-height: calc(36 / 27);

  @include elementSpacing;

  @media (max-width: $mobile) {
    font-size: 2.1rem;
    line-height: calc(27 / 21);
  }
}

h4,
.title4 {
  @include h4-font-styles();
}

@mixin h5-font-styles {
  font-family: $headlineFont;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: calc(27 / 18);

  @include elementSpacing;
}

h5,
.title5 {
  @include h5-font-styles();
}

h6,
.h6,
.title6 {
  font-family: $headlineFont;
}

.title7,
p.title {
  font-size: 2.4rem;
  line-height: calc(36 / 24);
}

p.para1 {
  margin-top: 40px;
}

p.para2 {
  margin-top: 50px;
}

.small-headline {
  text-transform: uppercase;
  color: color(accent, reflexBlue);
  letter-spacing: 0.15rem;
  font-weight: 600;

  &.gray {
    color: color(accent, darkGray);
  }

  &.black {
    color: color(black);
  }
}

@mixin p-font-styles {
  // "P3" is the default
  font-weight: 400;
  font-size: 1.6rem;
  line-height: calc(24 / 16);

  @media (max-width: $mobile) {
    line-height: calc(28 / 16);
  }

  &.p1 {
    font-size: 5.2rem;
    line-height: calc(64 / 52);

    @media (max-width: $mobile) {
      font-size: 2.3rem;
      line-height: calc(29 / 23);
    }
  }

  &.p2 {
    font-size: 2.4rem;
    line-height: calc(35 / 24);

    @media (max-width: $mobile) {
      font-size: 2rem;
      line-height: calc(28 / 20);
    }
  }

  &.p3 {
    // DEFAULT
    font-size: 1.6rem;
    line-height: calc(24 / 16);

    @media (max-width: $mobile) {
      line-height: calc(28 / 16);
    }
  }

  &.p4 {
    font-size: 1.3rem;
    line-height: calc(22 / 13);

    @media (max-width: $mobile) {
      font-size: 1.6rem;
      line-height: calc(26 / 16);
    }
  }

  &.p5 {
    font-size: 3.1rem;
    line-height: calc(43 / 31);

    @media (max-width: $mobile) {
      font-size: 1.3rem;
      line-height: calc(19 / 13);
    }
  }

  @include elementSpacing;

  sup {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  &.disclaimer {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-style: italic;
  }

  &.eyebrow {
    font-size: 1rem;
    line-height: 2.1rem;
  }
}

p,
ul,
ol {
  @include p-font-styles;
}

p {
  a:not(.btn) {
    color: color(accent, reflexBlue);
    font-weight: 600;
    text-decoration: underline;
  }
}

a.standard-link{
    color: color(accent, reflexBlue);
    font-weight: 600;
    text-decoration: underline;
}

ol {
  padding-left: 1em;
}

@mixin bullets {
  margin-left: $padding--xs;

  li {
    position: relative;
    padding: 0 0 0 1.5em;

    &:before {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: color(black);
      top: 0.6em;
      left: 0;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
  }

  &.bullets {
    position: relative;
    @include elementSpacing;
    @include bullets;
  }
}

ol {
  list-style: decimal;

  &.upper-alpha {
    list-style: upper-alpha;
    padding-left: 1.5em;
  }
  &.lower-alpha {
    list-style: lower-alpha;
    padding-left: 1.5em;
  }

  &.upper-roman {
    list-style: upper-roman;
  }
}

.strong,
strong {
  font-weight: 800;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.t-accent {
  color: color(accent);
}

.t-midnight {
  color: color(accent, midnight);
}

.t-body {
  font-family: $bodyFont;
}

.red-text {
  color: color(accent, red);

  &.bullets,
  .bullets {
    li::before {
      background-color: color(accent, red);
    }
  }
}

.t-sky {
  color: color(accent, sky);
}
