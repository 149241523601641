/****************************************

LEARNING JOURNEY

****************************************/

.learning-journey {
  .intro-content-wrapper {
    @media (max-width: $mobile) {
      padding-bottom: 0;
    }
  }

  .page-header {
    text-align: center;
    padding-bottom: $padding--lg;

    @media (max-width: $mobile) {
      padding-bottom: $paddingMain;
    }

    p {
      @media (min-width: theme('screens.lg')) {
        width: 60%;
        margin: auto;
      }
    }
  }
}

.current-courses {
  .course-data {
    min-width: 400px;
  }
  .card-layout {
    @media (max-width: $mobile) {
      margin: 0 calc(-1 * $padding--xs);
    }
  }

  .card {
    margin-bottom: $padding--sm;
    border-top: 15px solid color(accent, lilac);

    @media (max-width: $mobile) {
      padding: $paddingMain $padding--xs;
    }
  }

  .accordion-item {
    &.open {
      margin-bottom: 0;

      .accordion-details {
        max-height: none;
        padding: 3rem 0 0;
      }
    }

    .accordion-header {
      border-bottom: 1px solid color(accent, lilac);
      padding-bottom: $paddingMain;
      justify-content: space-between;

      .accordion-title {
        color: color(black);
      }

      .accordion-icon {
        font-size: 4rem;
        font-weight: 100;
      }
    }
  }

  .section-wrapper {
    border: 1px solid color(accent, lightGray);
    margin-bottom: $padding--sm;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &.flex {
      @media (max-width: theme('screens.lg')) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &.show-info .course-buttons {
      @media (min-width: $mobile) {
        width: 25%;
      }
    }

    &.no-show-info {
      @media (min-width: $mobile) {
        .course-content-wrapper {
          width: 25%;
        }

        .course-buttons {
          width: 100%;
        }
      }
    }

    .status-tag {
      position: absolute;
      right: 0;
      top: 1rem;
      padding: 5px 15px;
      background-color: color(black);
      color: white;
      font-weight: 600;
      border-radius: 999px 0 0 999px;

      &.sky {
        background-color: color(accent, sky);
      }

      &.lilac {
        background-color: color(accent, lilac);
      }

      &.mist {
        background-color: color(accent, mist);
        color: color(black);
      }

      &.lightgray {
        background-color: color(accent, lightGray);
        color: color(black);
      }

      &.red {
        background-color: color(accent, red);
      }

      &.peach {
        background-color: color(accent, peach);
      }
    }

    .title4 {
      padding-top: 2rem;
    }

    .subtitle {
      color: color(accent, darkGray);
    }

    hr {
      padding-top: 2rem;
      color: color(accent, darkGray);
    }
  }

  .course-content-wrapper {
    align-items: flex-start;
    padding-top: 2rem;

    @media (max-width: $mobile) {
      flex-direction: column;
      width: 100%;
    }

    .course-data {
      .cell {
        padding: 0.75rem;
      }
    }

    .course-buttons {
      padding-top: 1rem;

      @media (max-width: $mobile) {
        width: 100%;
      }

      .btn {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  .designation-anchor {
    position: relative;
    top: calc(-157px - 85px); // height of header + padding/border on card
  }
}

.recommended-courses {
  .static-callouts {
    padding-bottom: $padding--lg;
    text-align: center;

    .buttons {
      display: flex;
      justify-content: center;
      gap: 2rem;

      @media (max-width: $mobile) {
        flex-direction: column;
      }
    }
  }

  .promotional-callout {
    padding: $padding--lg $padding--sm;
    height: 100%;

    &.dark {
      background: $grad2Reverse;
      color: white;

      p {
        color: white;
      }
    }

    &.light {
      background: $grad5Reverse;
    }

    p {
      margin-bottom: $padding--lg;

      @media (max-width: $mobile) {
        margin-bottom: $paddingMain;
      }
    }
  }
}

.course-history {
  .card-layout {
    @media (min-width: $mobile) {
      padding-bottom: $paddingMain;
    }
  }

  .transcript {
    .card-holder {
      gap: 2rem;
    }

    .card-hr {
      margin: 2em 0;
      border-top: 1px solid color(accent, lightGray);
    }

    .buttons button:nth-of-type(2) {
      margin-top: 2rem;
    }

    button.download-btn {
      display: flex;
      gap: 1rem;
      justify-content: center;
    }

    button.view-transcript {
      white-space: nowrap;
      padding: 15px 0;
    }
  }

  .gpas {
    padding-bottom: $padding--xs;
  }

  .course-history-table {
    padding-top: 0;

    table {
      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    td {
      vertical-align: top;
    }

    .thead-layout {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      white-space: nowrap;
      letter-spacing: inherit;
      text-transform: inherit;

      &.active-sort {
        color: color(accent, reflexBlue);

        .desc {
          transform: rotate(180deg);
        }
      }
    }

    .view-grade {
      white-space: nowrap;
    }
  }
}

.current-courses, .recommended-courses, .course-history {
  @media (max-width: $mobile) {
    padding: 0;
  }
}

.recommended {
  padding-bottom: 7rem;
  .topbar--Continuing-Education {
    border-top: 5px solid color(accent, sky);
  }
  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    position: relative;

    .card-header {
      .view {
        font-size: 1.2rem;
        text-transform: uppercase;
        color: color(accent, base);
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .programContent {
    width: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .overlay {
      display: flex;
      justify-content: center;
      overflow-y: scroll;
      background: #fff;
      transition: all .5s;
      width: 200%;
      height: auto;
      max-height: 100vh;
      z-index: 9999;
      .card {
        height: 100%;
        justify-content: flex-start;
        padding: 3.5rem;
        width: 100%;
        align-items: flex-start;

        .collection-title {
          font-family: Cambon-Demi, serif;
          font-weight: 400;
          font-size: 4.8rem;
          line-height: 1;
          margin-bottom: 2rem;
          text-align: left;
        }

        .p4 {
          font-size: 1.8rem;
          line-height: 1.3888888889;
        }
        
        .p5 {
          font-size: 1.3rem;
          line-height: 1.6923076923;
        }

        .desc,
        .p4,
        .p5 {
          text-align: left;
        }

        .close {
          font-size: 40px;
          line-height: 0;
          position: absolute;
          top: 25px;
          right: 25px;
          cursor: pointer;
        }
      }
    }
  }
}
