/****************************************

DESIGNATION APPLICATION

****************************************/

.designation-app {
	.page-title {
		text-align: center;
		margin-bottom: $padding--lg;
	}

	.page-header {
		text-align: center;
		margin-bottom: $paddingMain;
	}

	.errors {
		text-align: center;
		margin-bottom: $paddingMain;
	}

	.table {
		margin: 0 auto;

		.row .cell {
			width: 50%;

			&.tooltip {
				display: flex;
				align-items: center;
				gap: 1rem;
			}
		}
	}

	.inline-field {
		margin-bottom: 2rem;

		@media (max-width: $mobile) {
			flex-direction: column;
			align-items: flex-start;

			select,
			input {
				width: 100%;
			}
		}
	}

	.field-row {
		display: flex;
		justify-content: space-between;
		gap: 2rem;

		@media (max-width: $mobile) {
			flex-direction: column;
			gap: 0;
		}

		.field {
			flex-grow: 1;
		}

		&.ed-level {
			.field {
				width: 50%;
			}
		}
	}

	.checkboxes {
		margin-bottom: 2rem;

		li:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}
	input[name="independent_advisor"] {
		top: 2px;
	}
	input[name="independent_advisor"] + .checkbox-input {
		font-size: 1.8rem;
		font-weight: bold;
		margin: 70px 0 50px;
	}

	.radio-question {
		margin-bottom: 2rem;

		@media (min-width: $mobile) {
			display: flex;
			justify-content: space-between;

			.radios-label {
				width: 66%;
			}

			.radios {
				margin-top: 1rem;
			}
		}

		.radios {
			display: flex;
			gap: $padding--lg;
		}
	}

	.buttons {
		display: flex;
		gap: 2rem;
		justify-content: center;
		margin-top: $paddingMain;
	}

	hr {
		margin: $padding--lg 0;
	}

	.employment-record {
		.years-error {
			margin-top: 2rem;
		}
		
		.card {
			margin-top: $paddingMain;
		}

		.supervisor-info p {
			margin-bottom: 0;
		}

		.tooltip-wrapper {
			float: right;
			padding: 1rem;

			.tooltip-buttons {
				display: flex;
				flex-direction: column;
				gap: 1rem;

				button {
					color: color(black);
					font-size: 1.6rem;
				}
			}
		}
	}

	.submit-error {
		text-align: center;
		margin-top: 2rem;
	}
}
