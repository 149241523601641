/****************************************

CONTAINERS

****************************************/

.container {
  max-width: calc(100% * (7/8));
  margin: 0 auto;

  @media (max-width: $mobile) {
    max-width: 100%;
    padding: 0 $padding--xs;
  }
}

.container--sm {
  max-width: 875px;
  margin: 0 auto;

  @media (max-width: $mobile) {
    padding: 0 $padding--xs;
  }
}

.container--full {
  width: 100%;
}

/****************************************

LAYOUTS

****************************************/

.column-layout {
  display: flex;
  justify-content: space-between;

  @media (max-width: $mobile) {
    flex-direction: column;

    &.mobile-vert-gap {
      gap: $padding--sm;
    }
  }

  .col {
    // defaults to 50/50
    width: calc((100% - #{$padding--lg}) / 2);

    @media (max-width: theme('screens.xl')) {
      width: calc((100% - #{$padding--sm}) / 2);
    }

    @media (max-width: $mobile) {
      width: 100%;
    }

    &--two-thirds {
      width: calc((100% * 2 / 3) - #{$padding--lg});

      @media (max-width: theme('screens.xl')) {
        width: calc((100% * 2 / 3) - #{$padding--sm});
      }

      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    &--third {
      width: calc((100%) * 1 / 3);

      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  &.sm-gap {
    .col {
      width: calc((100% - #{$padding--sm}) / 2);

      @media (max-width: $mobile) {
        width: 100%;
      }

      &--two-thirds {
        width: calc((100% * 2 / 3) - #{$padding--sm});

        @media (max-width: $mobile) {
          width: 100%;
        }
      }

      &--third {
        width: calc((100%) * 1 / 3);

        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }

  &.sticky-right {
    align-items: flex-start;

    .col {
      &:last-of-type {
        position: sticky;
        top: calc(143px + #{$padding--sm}); //change to nav height
      }
    }
  }
}

.card-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  
  .card-tab{
      padding: .5em 1em;
      background: $grad5;
      max-width: 50%;
      font-weight: 300;
      text-align:center;
      position:absolute;
      top:-2.5em;
      left:0;
  }
  
  &.halves {
    .card {
      position:relative;
      width: calc((100% - #{$padding--lg}) / 2);
      margin-bottom: $padding--lg;

      @media (max-width: theme('screens.xl')) {
        width: calc((100% - #{$padding--sm}) / 2);
        margin-bottom: $padding--sm;
      }

      @media (max-width: $mobile) {
        width: 100%;
      }

      &:not(:nth-of-type(even)) {
        margin-right: $padding--lg;

        @media (max-width: theme('screens.xl')) {
          margin-right: $padding--sm;
        }

        @media (max-width: $mobile) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
/*    .card-wrapper{
        .card {
            width:100%;
        }
    }*/

    &.sm-gap {
      .card {
        width: calc((100% - #{$padding--sm}) / 2);
        margin-bottom: $padding--sm;

        @media (max-width: $mobile) {
          width: 100%;
        }

        &:not(:nth-of-type(even)) {
          margin-right: $padding--sm;

          @media (max-width: $mobile) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &.thirds {
    .card {
      width: calc((100% - (#{$gap} * 4)) / 3);
      margin-bottom: calc(#{$gap} * 2);

      @media (max-width: theme('screens.md')) {
        width: 100%;
      }

      &:not(:nth-of-type(3n + 3)) {
        margin-right: calc(#{$gap} * 2);

        @media (max-width: theme('screens.md')) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &.quarters {
    .card {
      width: calc((100% - calc(#{$gap} * 6)) / 4);
      margin-bottom: calc(#{$gap} * 2);

      @media (max-width: theme('screens.md')) {
        width: 100%;
      }

      &:not(:nth-of-type(4n + 5)) {
        margin-right: calc(#{$gap} * 2);

        @media (max-width: theme('screens.md')) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &.fifths {
    .card {
      width: calc((100% - calc(#{$gap} * 8)) / 5);
      margin-bottom: calc(#{$gap} * 2);

      @media (max-width: theme('screens.md')) {
        width: 100%;
      }

      &:not(:nth-of-type(5n + 6)) {
        margin-right: calc(#{$gap} * 2);

        @media (max-width: theme('screens.md')) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: #{$padding--sm};

  @media (max-width: theme('screens.xl')) {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  @media (max-width: theme('screens.lg')) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media (max-width: theme('screens.md')) {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: #{$padding--xs};
  }

  .card {
    padding: $padding--xs;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: all 0.5s;

    @media (max-width: $mobile) {
    }

    .view {
      font-size: 1.4rem;
      text-transform: uppercase;
      color: color(accent);
      font-weight: 600;
    }

    &:nth-of-type(1) {
      //grid-column-end: 2;
      padding: $padding--sm;
      grid-column: 1/3;
      grid-row: 1/3;
      text-align: left;
      align-items: flex-start;

      @media (max-width: theme('screens.md')) {
        padding: $padding--xs;
        //grid-column: 1/2;
        //grid-row: 1/2;
      }

      .desc {
        display: block;
      }

      .view {
        display: none;
      }

      .overlay {
        display: none !important;
      }

      &:hover {
        transform: scale(1) !important;
      }
    }

    &:hover {
      transform: scale(1.05);
    }

    &.open {
      z-index: 9999;

      &:hover {
        transform: scale(1) !important;

        .card {
          transform: scale(1) !important;
        }
      }
    }
  }

  .desc {
    display: none;
  }
}

.wrap-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .card,
  .field,
  .group {
    width: calc((100% - #{$padding--sm}) / 2);
    @include elementSpacing;

    @media (max-width: theme('screens.xl')) {
      width: calc((100% - #{$padding--sm}) / 2);
    }

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .group {
    .card,
    .field {
      width: 100%;
      margin: 0 0 5px 0;
    }
  }
}

.form-layout {
  display: flex;
  column-gap: 40px;
  margin-bottom: 40px;

  @media (max-width: $mobile) {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
  }

  .col {
    // defaults to 50/50
    width: calc((100% - #{$padding--lg}) / 2);

    @media (max-width: theme('screens.xl')) {
      width: calc((100% - #{$padding--sm}) / 2);
    }

    @media (max-width: $mobile) {
      width: 100%;
    }

    &--two-thirds {
      width: calc((100% * 2 / 3) - #{$padding--lg});

      @media (max-width: theme('screens.xl')) {
        width: calc((100% * 2 / 3) - #{$padding--sm});
      }

      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    &--third {
      width: calc((100%) * 1 / 3);

      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    &--quarter {
      width: calc((100%) * 1 / 4);

      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  select,
  input {
    width: 100%;
  }
}

/****************************************

Chat Icon

****************************************/
.chat-icon {
  position: fixed;
  bottom: 20px !important;
  right: 20px !important;
}

