.toolkits {
  .card-holder {
    padding-bottom: 20px;
  } 
  .card-content {
    padding-top: 20px;
  }

  .col {
    &--fill {
      width: calc(100% * 2/3);
    }
    &--thirds {
      @media (min-width: $mobile) {
        width: calc(100% * 1/3);
      }
    }
    &--social {
      width: 100%;
      @media (min-width: $mobile) {
        width: calc(100% * 1/2);
      }

      &.card-header {
        margin-bottom: 20px;
      }
    }
    &--half, &--thirds {
      .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
  
        .buttons-wrapper {
          @media (max-width: $mobile) {
            width: 100%;
          }
        }
  
        .btn{ 
          @media (max-width: $mobile) {
            margin-top: 20px;
            width: 100%;
          }
        }
      }
    }
  }
}