/****************************************

TAX FORMS

****************************************/

.tax-forms {
	.card-layout {
		justify-content: flex-start;
	}

	.card-holder {
		@media (min-width: $mobile) {
			align-items: flex-start;
		}

		@media (max-width: $mobile) {
			flex-direction: column;
		}
	}

	.card.drop-box {
		min-height: 100px;

		.card-header {
			margin-bottom: 2rem;
		}

		.buttons {
			@media (max-width: $mobile) {
				width: 100%;
			}

			button:first-child {
				margin-bottom: 1rem;
			}
		}
	}
}
