/****************************************

PURCHASE HISTORY

****************************************/

.purchase-history {
  .content-wrapper {
    margin-top: 2rem;
    @media (min-width: theme('screens.lg')) {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 71fr 24fr;
      grid-column-gap: $padding--lg;
    }
  }

  .search-wrapper {
    @media (min-width: $mobile) {
      display: flex;
      align-items: center;
      gap: 2rem;

      div:first-child {
        flex-basis: 50%;
      }

      div:not(:first-child) {
        flex-grow: 1;
      }
    }

    .btn {
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  .sort-wrapper {
    padding: 2rem 0;

    @media (min-width: $mobile) {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }

    .sort-label {
      white-space: nowrap;

      @media (max-width: $mobile) {
        margin-bottom: 0.5rem;
      }
    }

    .radios {
      @media (min-width: $mobile) {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        flex-wrap: wrap;
      }
    }
  }

  .card {
    margin-bottom: $padding--sm;

    .title3 {
      margin-bottom: 2rem;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;

      @media (max-width: theme('screens.lg')) {
        padding-top: 2rem;
        .btn {
          width: 100%;
        }
      }
    }
  }
}
