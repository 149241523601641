.message-center-container {
  width: 100%;
  
  .messages {
    background: color(white);
    @include paddingTopBottom;

    .message-display-box {
      border-bottom: 1px solid color(accent, lilac);
      padding: $paddingMain;

      @media (max-width: $mobile) {
        padding: $paddingMain 0;
      }

      &:nth-of-type(1) {
        border-top: 1px solid color(accent, lilac);
      }

      .title {
        cursor: pointer;

        @media (max-width: $mobile) {
          align-items: flex-start;
        }

        p {
          margin: 0;
        }

        .title7 {
          width: calc(100% - 150px - 18px);
          display: flex;
          align-items: center;
          flex-direction: row;
          font-weight: 400;
          flex-grow: 1;

          @media (max-width: $mobile) {
            align-items: flex-start;
          }

          img {
            height: 20px;
            width: auto;
            margin-left: 10px;

            @media (max-width: $mobile) {
              margin-top: 1rem;
            }
          }
        }

        .date {
          width: 150px;
          flex: none;
        }

        .expand-toggle {
          width: 18px;
          height: 2px;
          display: block;
          background: color(black);
          flex: none;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: $mobile) {
            margin-top: 1.8rem;
          }

          &:after {
            content: '';
            width: 2px;
            height: 18px;
            display: block;
            background: color(black);
            transition: opacity 0.5s;
          }
        }
        .unreadMsg {
            font-weight: bold;
        }
      }

      .date.mobile {
        margin-bottom: 0;
      }

      .content {
        max-height: 0;
        overflow: hidden;
        transition: padding 1s, max-height 1s;

        .col {
          max-width: 800px;
        }

        .message-display-body {
          line-height: calc(27 / 16);
        }

        .btn {
          margin-top: $paddingMain;

          @media (max-width: $mobile) {
            width: 100%;
          }
        }
      }

      &.active {
        .title {
          padding-bottom: $paddingMain;

          @media (max-width: $mobile) {
            padding-bottom: 2rem;
          }

          .title7 {
            font-weight: 600;
            &.unreadMsg {
                font-weight: bold;
            }
          }

          .expand-toggle:after {
            opacity: 0;
          }
        }
        

        .content {
          max-height: 1000px;

          @media (max-width: $mobile) {
            padding-top: 2rem;
          }
        }
      }
    }
  }
  
  .message-display-date {
    p {
      padding: 0;
      margin: 0;
    }
    padding-right: 3em;
  }
}
