div.infoReview.complete-modal {
    .title2 { 
        margin-bottom: 2rem;
    }
    .introText {
        margin-bottom: 3rem;
    }
}
#annualInfoReviewForm{
    label{text-align:left;}
    ul.radios li {
        text-align:left;
    }
    .accordion-header{
        justify-content:space-between;
    }
    div.accordion-item:first-child{
        @extend .open;
    }
}