.cfp-toc-courses {
  td {
    padding: 5px 20px;
  }
  margin-bottom: 40px;
}

.begin-app {
  margin-top: 40px;
}

.complete-exam {
  label {
    font-weight: normal;
  }
}

#transferOfCredit {
  .info-section {
    margin: 40px 0;
    sup {
      display: inline-block;
      margin-bottom: 25px;
      line-height: 15px;
      margin-top: 10px;
    }
  }

  .info-row {
    padding: 30px 40px;
    border: 0.1em solid;
    border-color: color(accent, base);
    background-color: color(white);
    color: color(accent, base);
    margin-top: -2px;
    .inline-block {
      display: inline-block;
    }
    .float-right {
      float: right;
      @media (max-width: $mobile) {
        float: none;
        display: flex;
        margin-top: 20px;
        div:first-child {
          margin-right: 20px;
          padding-right: 20px;
          border-right: 1px solid color(accent, base);
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }

  + .disclaimer {
    margin-top: 40px;
    p {
      font-size: 1.4rem;
    }
  }

  .clu-courses {
    max-width: 500px;
    margin-top: 20px;
  }

  .field-electives {
    flex-direction: row;
    flex-wrap: wrap;
    label {
      flex-basis: 50%;
    }
  }

  button[type='submit'] {
    max-width: 200px;
  }
  .cfp-transfer-of-credit {
    margin-top: 40px;
  }
  input[type=checkbox]:after {
    left: 20px;
  }
}
#transferOfCredit,
#graduateApplicationForm {
  p.fileName{
    display:inline;
    float: left;
    margin-right: 10px;
  }
}

.modalContent {
  h4 {
    max-width: 75vw;
  }
}

#schoolModalForm,
#courseModalForm {
  min-width: 250px;
  max-width: 700px;
  width: 60vw;
  max-height: 60vh;
  overflow: scroll;
  scrollbar-color: color(accent);

  /* width */
  &::-webkit-scrollbar {
    height: 1.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 100px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: color(accent);
    border-radius: 100px;
  }
  @media (min-width: $mobile) {
    width: 60%;
  }
}