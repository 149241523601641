.portal-login-page,
.password-reset-page,
.new-returning-landing {
  margin-top: 12rem;
  margin-bottom: 16rem;

  @media (max-width: $mobile) {
    margin: 6rem auto 0;
  }

  &.column-layout:not(.new-returning-landing) {
    @media (min-width: $mobile) {
      .col:nth-of-type(1) {
        width: 33%;
      }

      .col:nth-of-type(2) {
        width: calc((100% - $padding--xs) / 2);
      }
    }
  }

  .logo {
    padding-bottom: $padding--sm;

    @media (max-width: $mobile) {
      padding-bottom: 2rem;
    }
  }

  h1 {
    @media (min-width: $mobile) {
      margin-bottom: 5rem;
    }
  }

  .login-desc p {
    margin-bottom: 0;
  }

  .underline-link {
    white-space: nowrap;
    color: color(accent, base);
    text-decoration: underline;
    border: 0;

    &.forgot-link {
      white-space: normal;
    }
  }
  .login-box {
    padding: 6rem 12rem;

    @media (max-width: $mobile) {
      padding: $padding--sm $padding--xs;
    }

    div.p4 {
      @include p-font-styles();
    }

    .flex {
      gap: 2rem;
      justify-content: flex-start;

      button:nth-of-type(1) {
        white-space: nowrap;
      }

      button:nth-of-type(2) {
        text-align: left;
      }

      @media (max-width: $mobile) {
        flex-direction: row;
      }
    }
  }
  .login-form {
    padding-top: 2em;
  }
}
.password-reset-page {
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    .logo-container {
      padding: 0 10px 0 10px;
      .nm-logo {
        height: 4rem;
      }
    }
    svg, img {
      display: block;
      margin: 0 auto;
    }
    @media (max-width: $mobile) {
      display: block;
    }
  }
  #validateAnswer {
    .radio-question {
      .radios {
        li {
          padding-bottom: 30px;
          label {
            display: inline-block;
          }
        }
      }
    }
  } 
  #verifyAnswer {
    .factor-code {
      margin-bottom: 2rem;
    }
  }
}

.new-returning-landing, .graduate_application-page {
  margin-top: 3rem;
  .card-layout {
    height: 100%;
  }
}
