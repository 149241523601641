.notif-banner,
.notification {
  background: $grad5;
  width: 100%;
  &.browserWarning{
      background: color(accent, red);
      p {
         font-size:1.8rem;
         color: color(white); 
      }
  }
  // strict styling on banner to enable centering
  .notif-wrapper.container--lg {
    margin: 0 auto;
    padding: 0 1.75rem;
    @media (min-width: 1400px) {
      width: 1400px;
    }
    @media (max-width: 1399px) {
      width: 100%;
    }
  }

  &.hide {
    display: none;
  }

  section.notif-block:not(:first-of-type) {
    border-top: 1px solid white;
  }

  .close {
    position: absolute;
    right: 1rem;
    font-size: 3rem;
    cursor: pointer;
    line-height: 1;
    color: color(black);
  }

  .notif-wrapper {
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;

    .eyebrow {
      flex-basis: 200px;
      text-align: right;
      letter-spacing: .12em;
      text-transform: uppercase;
    }
    @media (max-width: $tablet) {
      .desktop {
        display: none;
      }
    }

    @media (max-width: $mobile) {
      .eyebrow {
        display: none;
      }
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 10px;
    @media (max-width: $tablet) {
      font-size: 1.2rem;
    }
  }

  a {
    font-weight: 600;
    line-height: 1.5rem;
    font-size: 1.2rem;
    text-decoration: none !important;
    padding-bottom: 0.25rem;
  
    position: relative;
  
    &.center {
      margin: 0 auto;
      display: table;
      width: auto;
    }
    &:not(.desktop) {
      @media (max-width: $tablet) {
        margin-right: 20px;
      }
    }
  
    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: -0.1rem;
      display: block;
      width: 100%;
      height: 1px;
      background: color(accent, reflexBlue);
      transition: 1.1s cubic-bezier(0.19, 1, 0.22, 1);
    }
  
    &::before {
      transform: scaleX(0);
      transform-origin: left;
    }
  
    &::after {
      transform-origin: right;
      transition-delay: 0.25s;
    }
  
    &:hover {
      &::before {
        transform: scaleX(1);
        transition-delay: 0.25s;
      }
  
      &::after {
        transform: scaleX(0);
        transition-delay: 0s;
      }
    }
  }
}