/****************************************

Global

****************************************/
.portal {
  font-size: 1.6rem;
  background: color(background);

  .small-headline {
    font-size: 1.2rem;
  }
}

/****************************************

Breadcrumbs

****************************************/

.breadcrumbs {
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.12em;
  margin-bottom: $paddingMain;
  line-height: 2;
}

/****************************************

Breadcrumbs

****************************************/
.intro-content-wrapper {
  padding-bottom: $padding--sm;

  .page-intro-text {
    margin-top: $padding--xs;
  }

  .user-info {
    strong {
      font-weight: 600;
    }
  }
}
