/****************************************

ACCENTS

****************************************/

// Top borders
.topbar--lilac {
  border-top: 5px solid color(accent, lilac);
}

.topbar--Continuing {
  border-top: 5px solid color(accent, sky);
}

.topbar--Masters {
  border-top: 5px solid color(accent, peach);
}
