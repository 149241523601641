/****************************************

VARIABLES

****************************************/
@import '_functions';

// MSC
$animationDuration: 0.5s;

//fonts
$headlineFont: 'Cambon-Demi', serif;
$bodyFont: 'Graphik', sans-serif;

// break points
// use tailwind variables
// theme("screens.sm") : 640px
// theme("screens.md") : 768px
// theme("screens.lg") : 1024px
// theme("screens.xl") : 1280px
// theme("screens.2xl") : 1536px
$mobile: theme('screens.lg');
$tablet: 1200px;

//Spacing
$padding--xs: theme('spacing.7');
$padding--sm: theme('spacing.14');
$padding--lg: theme('spacing.28');
$paddingMain: $padding--sm;
$paddingMainMobile: 2rem;
$gap: theme('spacing.4');

$elementSpacingDesktop: 2rem;
@mixin elementSpacing {
  margin-bottom: $elementSpacingDesktop;
}

@mixin paddingAllSides {
  padding: $padding--lg;

  @media (max-width: theme('screens.md')) {
    padding: $paddingMainMobile;
  }
}

@mixin paddingAllSidesSm {
  padding: $padding--sm;

  @media (max-width: theme('screens.md')) {
    //padding: $paddingMainMobile;
  }
}

@mixin paddingTopBottomXs {
  padding: $padding--xs 0;
}

@mixin paddingAllSidesXs {
  padding: $padding--xs;
}

@mixin paddingTopBottom {
  padding: $padding--lg 0;

  @media (max-width: theme('screens.md')) {
    padding: $paddingMainMobile 0;
  }
}

@mixin paddingTopBottomSm {
  padding: $padding--sm 0;

  @media (max-width: theme('screens.md')) {
    //padding: $paddingMainMobile 0;
  }
}

@mixin paddingTopBottomXs {
  padding: $padding--xs 0;
}

//colors
$colors: (
  white: (
    base: #fff,
    transparent: rgba(255, 255, 255, 0.75),
  ),
  black: (
    base: #18181e,
  ),
  accent: (
    base: #4200ed,
    reflexBlue: #4200ed,
    midnight: #240066,
    red: #dc3c46,
    lightRed: #feeeef,
    peach: #ff8d7b,
    lilac: #6f66b1,
    mist: #cfcfff,
    lightMist: #ececff,
    smoke: #e5dbe7,
    charcoal: #18181e,
    lightGray: #e0e0e0,
    darkGray: #747487,
    sky: #0094ff,
    neutral: #394452,
  ),
  background: (
    base: #f6f6ff,
  ),
);

$grad2: linear-gradient(90deg, #210063 0%, #4200ed 100%);
$grad2Reverse: linear-gradient(90deg, #4200ed 0%, #210063 100%);
$grad3: linear-gradient(90deg, #4200ed 0%, #6f66b1 100%);
$grad5: linear-gradient(90deg, #cfcfff 0%, #ddf3ff 100%);
$grad5Reverse: linear-gradient(90deg, #ddf3ff 0%, #cfcfff 100%);
$grad6: linear-gradient(90deg, #14AC3F 0%, #5AE480 100%);
