.accordion-item {
  @include elementSpacing;

  .accordion-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-height: 30px;
    cursor: pointer;
    border-bottom: 1px solid #000;

    p {
      margin-bottom: 0;
    }

    .accordion-icon {
      min-width: 15px;
      margin-right: 5px;
      display: flex;
      font-size: 20px;

      span.open-icon {
        @include showLayer;

        line-height: 1;
        text-align: center;
      }

      span.close-icon {
        @include hideLayer;

        line-height: 1;
        text-align: center;
      }
    }

    .accordion-title {
      span.open-title {
        @include showLayer;
      }

      span.close-title {
        @include hideLayer;
      }
    }
  }

  .accordion-details {
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    padding: 0;
    visibility: hidden;
    transition: all 0.5s;
  }

  &.open {
    .accordion-header .accordion-icon {
      span.open-icon {
        @include hideLayer;
      }

      span.close-icon {
        @include showLayer;
      }
    }

    .accordion-header .accordion-title {
      span.open-title {
        @include hideLayer;
      }

      span.close-title {
        @include showLayer;
      }
    }

    .accordion-details {
      max-height: 9999px;
      padding: 20px 0;
      visibility: visible;
    }
  }
}
