.continuing-education {
  margin-top: 2em;
  .purple-text {
    color: color(accent, base);
    display: flex;
    padding-top: 1.3em;
    padding-left: 2em;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .radios {
    justify-content: flex-start;
    // margin-top: 2em;
    margin-bottom: 2em;
  }
  input {
    margin-right: 1em;
  }

  label {
    font-size: 1.4rem;
    font-weight: 600;
    // margin-bottom: $gap;
    white-space: nowrap;
  }
  .field {
    display: inline;
    input {
      margin-top: 0.6em;
    }
    select {
      margin-top: 0.6em;
    }
  }
  .state {
    display: flex;
  }
}
