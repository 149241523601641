/****************************************

GLOBAL

****************************************/

/****************************************
REBASE
****************************************/
.debug-title {
  display: none;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  color: color(black);
  font-family: $bodyFont;
  background-color: #fafaff;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
  }
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  color: color(black);
}

main {
  //min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  section {
    @include paddingTopBottom;
  }

  &> div:last-child {
    margin-bottom: $padding--lg;

    @media (max-width: $mobile) {
      margin-bottom: $paddingMain;
    }
  }
}

/****************************************
Responsive switches
****************************************/
.desktop {
  display: block;

  @media (max-width: $mobile) {
    display: none;
  }
}

.mobile {
  display: none;

  @media (max-width: $mobile) {
    display: block;
  }
}

/****************************************
Structural components
****************************************/
section,
.full {
  width: 100%;
  position: relative;
}

/****************************************
Responsive video frame
****************************************/
.responsive-video {
  width: 100%;

  .video-size-frame {
    height: 0;
    padding-bottom: 56.3%;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/****************************************
Image crop
****************************************/

.img-crop {
  width: 100%;
  padding-bottom: 66.7%;
  position: relative;

  &.two-three {
    padding-bottom: 66.7%;
  }

  &.sixteen-nine {
    padding-bottom: 56.3%;
  }

  &.square {
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/****************************************
quick show/hide layers
****************************************/
@mixin showLayer {
  position: relative;
  top: auto;
  width: 100%;
  pointer-events: auto;
  opacity: 1;
  z-index: 1;
  visibility: visible;
}

@mixin hideLayer {
  position: absolute;
  width: 100%;
  opacity: 0;
  left: 0;
  pointer-events: none;
  z-index: -5;
  visibility: hidden;
}

/****************************************
flex layouts
****************************************/
@mixin flexCenter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: theme('screens.sm')) {
    flex-direction: column;
  }
}

.flex {
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-center {
  @include flexCenter;

  .col {
    width: 100%;
    flex: 1 1 auto;
    flex-direction: column;
  }
}

/****************************************
ui basics
****************************************/
@mixin internalNav {
  @include flexCenter;
}

@mixin internalNavLink {
  width: 100%;
  flex: 1 1 auto;
  text-align: center;
}

/****************************************
UTILITY CLASSES
****************************************/
.relative {
  position: relative;
}

/****************************************
BACKGROUNDS
****************************************/
@mixin bgFill() {
  background-size: cover;
  background-position: center center;
}

.bg-fill {
  @include bgFill();
}

.bg-reveal {
  @include bgFill();
  background-attachment: fixed;
}
