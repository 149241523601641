.ethics-questionnaire{
    .accordion-item{
        border-bottom: 1px solid #6f66b1;
        .accordion-header{
            justify-content: space-between;
        }
    }
    .accordion-title, .accordion-icon {
        margin-bottom: 1em;
        margin-top:5rem;
    }
    .prpStatusDef{
          text-align: left;
          padding: 1em 1em 0;
      }
  
   .promo-section {
        background-color: white;
        padding: 4rem;
        border: 1px solid color(accent, reflexBlue);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        margin-bottom:3rem;
        .promo-submit {
           flex-direction:row;
           flex-wrap:wrap;
           gap:1rem 2rem;
           @media (max-width: $mobile) {
               * {
                   flex: 0 1 100%;
               }
           }
           /*input {flex: 1 0 25%;}*/ 
            label{
                 flex: 0 1 100%;
                 font-weight:600;
            }
        }
        .promoCodeInput{
            border: 1px solid black;
            width:25%;
                @media (max-width: $mobile) {
                    padding:1rem;
                }
        }
   }
   
}  