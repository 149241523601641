.drop-box {
  background: color(white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.padding {
  &--lg {
    padding: $padding--lg;

    @media (max-width: $mobile) {
      padding: $padding--sm;
    }
  }

  &--sm {
    padding: $padding--sm;
  }
}

.m-btm-0 {
  margin-bottom: 0;
}

.card {
  h1,
  h2,
  h3,
  h4,
  h5,
  .title1,
  .title2,
  .title3,
  .title4 {
    margin-bottom: 0;
  }
}

.column-layout {
  .card {
    width: 100%;
    @include elementSpacing;
  }
}

html,
body,
#root {
  scroll-behavior: smooth;
}
