.billing-form {
  label {
    font-size: 1.5em;
  }
  .checkbox {
    height: 1.5em;
    width: 1.5em;
    color: color(accent, base);
    margin-bottom: 0.5em;
  }

  a {
    color: color(accent, base);
  }
  .expire-date {
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: flex-end;
    label {
      padding: 0;
      margin: 0;
    }
  }
  .date-dropdown {
    width: 100%;
    span {
      color: color(accent, red);
      display: inline-block;
    }
  }
  .other-payment {
    border: 0.1em solid;
    border-color: color(accent, base);
    background-color: color(white);
    color: color(accent, base);
  }

  .returning-user {
    margin-bottom: 5em;
    p {
      margin-bottom: 0.5em;
      padding: 0;
    }
    div {
      margin-bottom: 2em;
      align-items: flex-start;
    }
  }
  .applePay {
    height: 2em;
  }
  .credit-card {
    font-size: 1.4em;
    font-weight: 500;
    white-space: nowrap;
  }
  .credit-button {
    @media (max-width: $mobile) {
      padding-top: 1.46em;
      padding-bottom: 1.46em;
    }
  }
  svg {
    display: inline-block;
  }
}
