/****************************************

PROGRESS BAR

****************************************/

.progress-bar-wrapper {
  background: $grad5;
  justify-content: center;
  height: 100%;

  .progress-text {
    width: 75%;
    margin: 0 auto 1rem;
    text-align: center;
    font-weight: 600;
  }

  .dots-progress-title {
    font-weight: bold;
    text-align: center;
    color: color(accent, midnight);
    margin-bottom: 2rem;
    font-size: 1.8rem;

    .num {
      font-size: 4rem;
      margin: 0 0.5rem;
      line-height: 1;
    }
  }

  .progress-bar-bg {
    background: white;
    width: 75%;
    margin: 0 12.5%;
    border-radius: 999px;

    .progress-bar {
      background: $grad3;
      height: 2rem;
      border-radius: 999px;
    }
  }
}

.stepped-progress-bar-wrapper {
  background: $grad5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $padding--xs;
  height: 80%;

  @media (max-width: $mobile) {
    min-height: 200px;
  }

  .progress-bar-bg {
    background: $grad6;
    width: 75%;
    margin: 0 auto;
    border-radius: 999px;
    display: flex;
    justify-content: space-between;
    height: 4rem;
    @media (max-width: $mobile) {
        width: 90%;
      }
    .step {
      flex-grow: 1;
      height: 100%;
      background-color: white;

      &.clear {
        background-color: transparent;
      }
    }

    .step1 {
      border-radius: 999px 0 0 999px;
    }

    .step2 {
      border-right: 1px solid color(black);
      border-left: 1px solid color(black);
    }

    .step3 {
      border-radius: 0 999px 999px 0;
    }
  }

  .subtext {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    @media (max-width: $mobile) {
        width: 90%;
      }
    .text-step {
      text-align: left;
      width: calc(100% / 3);
    }
  }
}

.circular-progress-bar {
  min-height: 200px;

  .circular-progress-title {
    font-weight: bold;
    text-align: center;
    color: color(accent, midnight);
    margin-bottom: 2rem;
    font-size: 1.4rem;

    .num {
      font-size: 3rem;
      margin: 0 0.5rem;
      line-height: 1;
    }
  }
}
