/****************************************

DEBUG

****************************************/

/*
.col {
	border: 1px solid rgba(0, 0, 0, 0.25);
}

.cont,
.container {
	border: 1px solid rgba(0, 0, 255, 0.25);
}

.module {
	position: relative;
	min-height: 25px;
}
*/

.debug-title {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: 1px solid red;
  padding: 5px 10px;
  z-index: 99;
  font-size: 14px;
}
