.promo-code-modal {
  @media (max-width: $mobile) {
    font-size: small;
    margin: 0;
    padding: 0;
  }
  p {
    @media (max-width: $mobile) {
      font-size: small;
      padding: 0;
      margin: 0;
    }
  }
}

.promo-info-bubble {
  border-radius: 50%;
  background-color: color(accent, midnight);
  color: color(white);
  height: auto;
  width: auto;
  text-align: center;
  margin-left: 1em;
}

.promo-code-cols {
  padding-top: 3em;

  @media (max-width: $mobile) {
    flex-direction: column;
    padding-top: 1em;
    width: 100%;
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }
  &.disclaimer {
    text-align: center;
  }

  label,
  input,
  select {
    //manually extending p.p5
    font-size: 1.3rem;
    line-height: calc(22 / 13);
  }
}

.error-box {
  background-color: color(accent, smoke);
  display: flex;
  color: color(accent, red);
  .promo-info-bubble {
    background-color: color(accent, red);
    color: color(white);
    max-width: 1.4em;
    max-height: 1.4em;
    margin-right: 1em;
    margin-top: 0.3em;
  }
}

.red-star {
  color: color(accent, red);
}
.formButton {
  margin-top: 3em;
  @media (max-width: $mobile) {
    margin-top: 0.5em;
  }
}
