/****************************************

HEADER

****************************************/

header {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 999;

  &.nav-simple,
  &.nav-full .primary-nav {
    background: $grad2;
    color: color(white);
  }

  .masquerading-logout {
    position: absolute;
    z-index: 99999;
    left: 20px;
    top: 20px;
  }

  &.nav-simple {
    @include paddingAllSidesXs;
    margin-bottom: $paddingMain;

    .nav-items {
      gap: 0.75rem;

      @media (min-width: theme('screens.md')) {
        gap: 1.5rem;
      }

      .link-icon {
        margin-right: 0.25rem;
        width: 16px;
        height: 16px;
      }
    }
  }

  &.nav-full {
    padding-bottom: $paddingMain;

    @media (max-width: theme('screens.lg')) {
      padding-bottom: $paddingMainMobile;
    }

    .utility-nav {
      @include paddingTopBottomXs;
      background-color: white;
      width: 100%;
      font-size: 1rem;
      text-transform: none;
      letter-spacing: 1px;

      display: flex;
      gap: 1.5rem;
      justify-content: space-between;
      align-items: baseline;

      @media (max-width: $mobile) {
        @include paddingAllSidesXs;
        justify-content: flex-end;
      }

      .utility-nav-item {
        &.enroll-now-button {
          @include btnStyle;
          display: inline-block;
          font-size: 0.875rem;
          line-height: 1.25rem;
          padding: 5px 10px;
        }
      }

      .link-wrapper {
        &.my-account {
          background: white;
          position: absolute;
          right: 190px;
          top: 40px;
          margin: 10px 0px;

          .my-account-menu {
            .utility-nav-item {
              display: block;
              padding: 20px 10px;
              border: none;
            }
          }
        }

        &.left {
          display: flex;
          align-items: center;

          @media (max-width: $mobile) {
            display: none;
          }

          a {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            font-weight: 600;

            &:not(:last-of-type) {
              margin-right: $padding--xs;
            }

            img {
              width: 16px;
              height: auto;
              margin-right: 5px;
            }
          }
        }

        &.right {
          display: flex;
          align-items: center;

          .utility-nav-item {
            border: 1px solid #e0e0e0;
            border-radius: 100px;
            padding: 10px 15px;
            font-size: 1.4rem;
            letter-spacing: 1px;
            font-weight: 600;

            &:not(:last-child) {
              margin-right: $padding--xs;
            }
          }
        }
      }

      .msg-center-title {
        @media (max-width: $mobile) {
          display: none;
        }
      }
    }

    .primary-nav {
      @include paddingTopBottomXs;

      .primary-nav-desktop {
        display: none;

        @media (min-width: theme('screens.lg')) {
          display: flex;
          gap: 3rem;
        }
      }

      .hamburger {
        color: color(accent, peach);

        @media (min-width: theme('screens.lg')) {
          display: none;
        }

        svg {
          width: 2.5rem;
          height: 2.5rem;

          @media (min-width: theme('screens.md')) {
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }
  }
}

.desktop-menu-drawer {
  pointer-events: none;

  .MuiBackdrop-root {
    display: none;
  }

  .MuiPaper-root {
    pointer-events: auto;
    width: 100%;
    height: auto;
    top: 143px; // find a better way to do this? needs to come in just below the header
  }

  .close-button {
    color: color(black);
    align-self: flex-end;

    &:hover {
      background-color: inherit;
    }

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .desktop-menu-container {
    @include paddingAllSidesSm;
    padding-top: 0;
    align-items: inherit;
    gap: 4rem;
  }

  a.secondary-nav-item:hover {
    color: color(accent, reflexBlue);
  }

  .secondary-nav-item {
    @include h3-font-styles();
    margin-bottom: 0.75rem;
  }

  .secondary-nav {
    width: 70%;
  }

  .articles-container {
    width: 30%;

    .browse-link {
      margin-top: 3rem;
    }

    .menu-article {
      gap: 2rem;

      .article-image {
        width: 33%;
      }

      .title4 {
        margin-bottom: 0.75rem;
      }

      .blue-arrow {
        display: none;
        margin-left: 2rem;
      }

      &:hover {
        .black-arrow {
          display: none;
        }

        .blue-arrow {
          display: block;
        }
      }
    }
  }
}

.mobile-nav-drawer {
  .drawer-header {
    @include paddingAllSidesXs;

    .close-hamburger {
      color: color(accent, reflexBlue);

      svg {
        width: 2.5rem;
        height: 2.5rem;

        @media (min-width: theme('screens.md')) {
          width: 4rem;
          height: 4rem;
        }
      }
    }
  }

  .primary-nav-mobile {
    @include paddingAllSidesXs;

    .primary-nav-item {
      @include h3-font-styles;
      margin-bottom: 1.5rem;
    }

    .contact-item {
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      display: flex;
      gap: 0.5rem;
    }

    .enroll-now {
      margin-top: 3rem;

      .btn {
        width: 100%;
      }
    }
  }
}

.portal header.nav-full {
  background: color(white);
  padding-bottom: 0;
  margin-bottom: $paddingMain;

  .logo {
    svg {
      height: 50px;
      width: auto;
    }
  }

  .primary-nav-desktop {
    a {
      font-size: 1.6rem;
      position: relative;
      &:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: -0.1rem;
        display: block;
        width: 0;
        height: 1px;
        background: color(accent, peach);
        transition: all 1.1s cubic-bezier(.19,1,.22,1);
        transform: scaleX(0);
        transform-origin: left;
      }
      &:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: -0.1rem;
        display: block;
        width: 0;
        height: 1px;
        background: color(accent, peach);
        transition: all 1.1s cubic-bezier(.19,1,.22,1);
        transform-origin: right;
        transition-delay: .25s;
      }
      &:hover:before {
        width: 100%;
        transform: scaleX(1);
        transition: all 1.1s cubic-bezier(.19,1,.22,1) .25s, width 0s .25s;
      }
      &:hover:after {
        width: 100%;
        transform: scaleX(0);
        transition: all 1.1s cubic-bezier(.19,1,.22,1) 0s, width 0s;
      }
    }
  }

  .dropdown {
    cursor: pointer;
    position: relative;

    span {
      display: flex;
      align-items: center;

      &:after {
        content: '';
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid color(accent);
        margin-left: 5px;
        transition: transform 0.5s;
      }
    }

    ul {
      max-height: 0;
      overflow: hidden;
      position: absolute;
      background: color(white);
      left: 0;
      top: 35px;
      width: 150px;
      padding: 0;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0);
      transition: padding 0.5s, box-shadow 0.5s, max-height 0.5s;
      z-index: 2;
    }

    a,
    button {
      font-size: 1.4rem;
      text-decoration: none;
      padding: 5px 10px;
      letter-spacing: 1px;
      line-height: 1;
    }

    &:hover {
      span {
        &:after {
          transform: rotate(180deg);
        }
      }

      ul {
        max-height: 500px;
        padding: 10px 0;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);

        li {
        }
      }
    }
  }

  .message-center {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 20px !important;

    img {
      width: 14px;
      height: auto;
      margin-right: 5px;
    }

    .count {
      position: absolute;
      border-radius: 50%;
      background: color(accent, red);
      width: 24px;
      height: 24px;
      top: -10px;
      right: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: color(white);
      font-weight: bold;
    }
  }
}
