.intro-content-container {
  p {
    padding: 0;
    margin: 0;
    padding-bottom: 0.1em;
  }
  .intro-content-text {
    // font-size: 1.5em;
  }
  .student-credentials p {
    font-size: 2em;
    font-weight: 500;
  }
}
