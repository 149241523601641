.greyed-out {
  opacity: calc(50%);
}
.scroll-box {
  overflow-x: hidden;
  overflow-y: auto;
  h2 {
    text-align: center;
  }
  button {
    margin: auto;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  p {
    text-align: center;
    @media (max-width: $mobile) {
      font-size: 1.8rem;
      line-height: calc(35 / 24);
    }
  }
  @media (max-width: $mobile) {
    margin: 0;
    padding: 0;
  }
}

.edit-account-submit-wrapper {
  width: 100%;
  padding: 2rem 0;

  input {
    margin: auto;
  }
}
