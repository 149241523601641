/****************************************

BUTTONS

****************************************/

@mixin btnStyle {
  padding: 15px 25px;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 600;
  border-radius: 50px;
  color: color(white);
  background-color: color(accent);
  text-decoration: none;
  text-align: center;
  display: table;
  align-items: center;
  cursor: pointer;
  transition: border $animationDuration, background-color $animationDuration,
    color $animationDuration;

  &:hover {
    background-color: color(accent, peach);
    color: color(white);
  }
}

.btn {
  @include btnStyle;

  &.center {
    margin: 0 auto;
    display: table;
    width: auto;
  }

  &.secondary {
    border: 1px solid color(accent);
    color: color(accent, midnight);
    background-color: color(white);
    padding: 10px 25px;

    &:hover {
      border: 1px solid color(accent, peach);
      background-color: color(accent, peach);
      color: color(white);
    }

    &.apply {
      border-radius: 3px;
      padding: 5px 10px 7px 10px;
    }
    
    &.large {
      padding: 15px 25px;
    }
    &.selected{
        border: 1px solid color(accent, midnight);
        background-color: color(accent, midnight);
        color: color(white);
    }
  }

  &.tertiary {
    color: color(accent, midnight);
    background-color: color(accent, peach);

    &:hover {
      background-color: color(accent, smoke);
      color: color(accent, midnight);
    }
  }

  &.disabled {
    background: $grad5;
    pointer-events: none;
    border: none;
    color: color(accent, midnight);
  }

  &.wide {
    padding: 15px 8px;
  }

  &.add-bottom-margin {
    margin-bottom: 10px;
  }
  
  &.adjust-width {
    width: 100%;
  }
  &.desktop {
    display: block;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media (max-width: $mobile) {
      display: block;
    }
  }
}

.underline-link {
  font-weight: 600;
  border-bottom: 1px solid color(accent, reflexBlue);
  line-height: 1.5rem;
  text-decoration: none;
  padding-bottom: 0.25rem;

  &.reflexblue {
    color: color(accent, reflexBlue);
  }

  &.peach {
    border-color: color(accent, peach);
  }
}

.link-later {
  &:after {
    content: '*';
    color: color(accent, red);
  }
}
