/****************************************

RESOURCE LIBRARY

****************************************/
@import '../../components/subcomponents/_swiper';

.resources {
  .resources-callout {
    background: $grad2;
    color: color(white);
    margin: $padding--lg 0;
    padding: calc($padding--lg + $paddingMain);

    @media (max-width: $mobile) {
        text-align: center;
        padding: $paddingMain $padding--xs;
        margin: $paddingMain 0;
    }

    p {
      color: color(white);
    }

    .buttons {
        display: flex;
        align-items: center;
        gap: 2rem;

        @media (max-width: $mobile) {
            flex-direction: column;

            .btn {
                width: 100%;
            }
        }
    }
  }
  .resourceGrid{
      display:grid;
      justify-content: stretch;
      align-content: space-evenly;  
      grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
      gap: 3rem;
      
      .resourceCell{
          /* Some boostrap logic here, too.  */
         // Temp sizing etc until bootstrap time 
         /*width:275px;*/
         .infoRow{
             font-size:14px;
         }
         h1.headline{
            font-size: 16px;
         }
         h2.byline{
             font-size:12px;
             font-family: "Graphik", sans-serif;
             color:#747487;
         }
         .resourceCover{
            width:100%;
            position:relative;
            .resourceImg{
                width:100%;
                height:135px;
                object-fit:cover;
            }
           .saveResourceIcon{
               position:absolute;
               bottom:1px;
               right:1px;
                padding:5px;
               height:30px;
               width:30px;
               background-color: color(background);
               text-align:center;
               cursor:pointer;
           }
        }
         
      }  
  }
  .static {
    &.resourceGrid{
      justify-content: stretch;
      align-content: space-evenly;  
      grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
      gap: 3rem;
      
        .resourceCell{
            .resourceCover{
                .resourceImg{
                    height:200px;
                }
            }
        }
    }
  }
    .swiper {
        swiper-slide{
            .resourceCell{
                padding-bottom:1em;
               .infoRow{
                    font-size:14px;
                    padding: .5em 0;
               }
               h1.headline{
                  font-size: 18px;
               }
               h2.byline{
                   font-size:12px;
                   font-family: "Graphik", sans-serif;
                   color:#747487;
                    padding: .5em 0;
               }
               .resourceCover{
                  width:100%;
                  position:relative;
                  .resourceImg{
                      width:100%;
                      object-fit:cover;
                  }
                 .saveResourceIcon{
                     position:absolute;
                     bottom:1px;
                     right:1px;
                      padding:5px;
                     height:30px;
                     width:30px;
                     background-color:#f6f6ff;
                     text-align:center;
                     cursor:pointer;
                 }
              }

            } 
        }
        .arrows{
            div{
                cursor:pointer;
            }
        }
    }
  button.loadMore{
      margin: auto;
  }
  .resourceFilters{
      @include fieldStyle;
      flex-direction: row;
      flex-wrap: wrap;
      flex-direction: column;
      gap: $paddingMain;

      form{display:contents;}
      form > .searchFields{
            display: flex;
            flex-grow: 1;
            justify-content:flex-start;
            gap:2rem;

            @media (max-width: $mobile) {
                flex-direction: column;
            }

                .inline-field{
                    flex-grow:2;
                    justify-content:flex-start;
                    input{
                        flex-grow:3;
                    }
                    select{
                        flex-grow:1;
                    }
                    &.select{
                        flex-grow:1;
                    }
                    &.search{
                        flex-grow:4;
                    }

                    label {
                        @media (min-width: $mobile) {
                            margin-bottom: 0;
                        }
                    }

                    @media (max-width: $mobile) {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0;

                        input, select {
                            width: 100%;
                        }
                    }
                }
                .searchButtons{
                     button {
                       margin: 0 5px;
                       display:inline;
                     }

                @media (max-width: $mobile) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 2rem;
                    margin-top: 1.5rem;

                    .btn {
                        width: 100%;
                    }
                }
            }
      }

      .title-row {
        margin-bottom: $paddingMain;

        @media (max-width: $mobile) {
            margin-top: $paddingMain;
            text-align: center;
            flex-direction: column;
            gap: 2rem;
        }

        .title-wrapper {
            white-space: nowrap;

            &> * {
                margin-bottom: 0;
            }
        }
      }

        .filterOptions{
            display: flex;
            justify-content:flex-end;
            flex-grow: 1;
            width:100%;

            @media (max-width: $mobile) {
                flex-direction: column;
                gap: 1rem;
            }

            button{
              margin: 0 2.5px;
              display:inline;
            }
        }
  }
  .resource-all-caps{
    text-transform: uppercase;  
  }
}
