.portal-forgot-info-page {
  display: flex;
  justify-content: center;
  .underline-link {
    white-space: nowrap;
    color: color(accent, base);
    text-decoration: underline;
    border: 0;
  }
  .title5 {
    font-size: 3em;
    line-height: 1.2em;
    padding-bottom: 1em;
  }
  .login-box {
    padding: 8em;
    .flex {
      justify-content: space-evenly;
    }
  }
  .login-form {
    padding-top: 2em;
  }
  .radio-group {
    padding-bottom: 2em;
    justify-content: space-evenly;
    input {
      margin-right: 0.5em;
    }
  }
  .card {
    margin-bottom: 0;
  }
}
