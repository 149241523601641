@mixin fieldStyle {
  display: flex;
  font-size: 1.6rem;

  label {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: $gap;

    span {
      color: color(accent, red);
    }
  }

  //input,
  select,
  .search-dropdown-btn,
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='date'],
  input[type='phone'],
  input[type='address'],
  input[type='city'],
  input[type='zip'],
  textarea {
    border: 1px solid color(black);
    border-radius: 2px;
    font-size: 1.6rem;
    padding: 10px 20px;
    min-height: 37px;
  }

  select,
  .search-dropdown-btn {
    padding: 10px 30px 10px 20px;
  }

  input[type='file'] {
    width: 100%;
  }

  input[readonly] {
    outline: none;
    background-color: color(accent, lightGray);
  }

  p {
    margin: $gap 0 0 0;

    &.disclaimer {
      font-style: italic;
    }
  }
}

.field {
  @include fieldStyle;
  @include elementSpacing;

  flex-direction: column;
}

.inline-field {
  @include fieldStyle;

  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .display-field {
    margin-bottom: $gap;
  }
}

.req-star,
.field-error {
  color: color(accent, red);
}

ul.checkboxes {
  padding-left: 2rem;

  li {
    position: relative;
  }
}

input[type='checkbox'] {
  appearance: none;
  width: 25px;
  height: 25px;
  position: absolute;
  left: -2rem;
  cursor: pointer;

  &:after {
    content: ' ';
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid color(black);
    border-radius: 2px;
    position: absolute;
    top: 4px;
    left: 0;
    background-color: white;
    cursor: pointer;
  }

  &:checked:after {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='0.5' y='0.5' width='17' height='17' rx='1.5' fill='%234200ED'/%3e%3cmask id='mask0_1503_2219' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='2' y='4' width='14' height='10'%3e%3cpath d='M6.59422 11.9063L3.99172 9.30379C3.8516 9.16335 3.66136 9.08442 3.46297 9.08442C3.26458 9.08442 3.07434 9.16335 2.93422 9.30379C2.64172 9.59629 2.64172 10.0688 2.93422 10.3613L6.06922 13.4963C6.36172 13.7888 6.83422 13.7888 7.12672 13.4963L15.0617 5.56129C15.3542 5.26879 15.3542 4.79629 15.0617 4.50379C14.9216 4.36335 14.7314 4.28442 14.533 4.28442C14.3346 4.28442 14.1443 4.36335 14.0042 4.50379L6.59422 11.9063Z' fill='black'/%3e%3c/mask%3e%3cg mask='url(%23mask0_1503_2219)'%3e%3crect width='18' height='18' fill='white'/%3e%3c/g%3e%3c/svg%3e");
    background-position: -1px -1px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
  }
}

ul.radios {
  li {
    position: relative;
  }

  label {
    margin-left: 2rem;
  }
}

input[type='radio'] {
  appearance: none;

  &:after {
    content: ' ';
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid color(black);
    border-radius: 999px;
    position: absolute;
    top: 4px;
    background-color: white;
    cursor: pointer;
  }

  &:checked:after {
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='0.5' y='0.5' width='17' height='17' rx='8.5' fill='white'/%3e%3ccircle cx='9' cy='9' r='4.5' fill='%234200ED'/%3e%3crect x='0.5' y='0.5' width='17' height='17' rx='8.5' stroke='%2318181E'/%3e%3c/svg%3e");
    background-position: -1px -1px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
  }
}

.field-radio label,
.field-checkbox label {
  position: relative;
  padding-left: 20px;

  input[type='radio'] {
    position: absolute;
    left: 0px;
    top: 1px;
  }
}

.search-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  @include fieldStyle;

  .search-dropdown-btn {
    display: inline-block;
    overflow: hidden;
    text-align: left;
    width: 100%;
    cursor: pointer;
    background-color: white;
  }

  .search-dropdown-content {
    position: absolute;
    background-color: #f6f6f6;
    overflow: auto;
    border: 1px solid color(black);
    z-index: 1;
    width: 100%;

    .search-input {
      width: 100%;
    }

    ul {
      overflow-y: auto;
      list-style-type: 'none';
      max-height: 350px;
      padding: 0;
      background-color: white;

      li {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        cursor: pointer;
        &:hover {
          background-color: #ddd;
        }
      }
    }
  }

  select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: none !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    pointer-events: none;
  }
}
